<template>
  <Teleport to="body">
    <transition name="modal">
      <div v-if="isOpen" @mouseup.self="closeModal" class="modal">
        <div class="modal__content">
          <div class="modal__body">
            <slot :close="closeModal" />
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
export default (await import("vue")).defineComponent({
  name: "Modal",

  emits: ["close"],

  data: () => ({
    isOpen: false,
  }),

  methods: {
    /** @public */
    closeModal() {
      this.$emit("close");
      this.isOpen = false;
    },

    /** @public */
    openModal() {
      this.isOpen = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  padding: 0px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba($color: #000000, $alpha: 0.7);

  &__content {
    width: 100%;
    border-radius: 8px;
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    box-shadow: 0 4px 15px rgba(135, 144, 167, 0.2);
  }
  &__body {
    flex: 1;
    overflow: hidden auto;
  }
}

.modal-enter-from,
.modal-leave-to {
  transform: translateY(100%);
}

.modal-enter-from,
.modal-leave-to > .modal__content {
  transform: translateY(-100%);
}

.modal-enter-active,
.modal-leave-active {
  transition: transform 0.3s;
}

.modal-enter-active,
.modal-leave-active > .modal__content {
  transition: transform 0.3s;
}
</style>
