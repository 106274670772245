<template>
  <Carousel :items-to-show="1" :wrap-around="true" class="carousel">
    <Slide v-for="(img, i) of images" :key="i">
      <img class="carousel__img" :src="img" />
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
import { PHOTOS } from "@/constants/photos";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default (await import("vue")).defineComponent({
  name: "BaseCarousel",

  components: { Carousel, Slide, Navigation },

  props: {
    id: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    PHOTOS,
  }),

  computed: {
    images() {
      const { images: photos } = this.PHOTOS.find(
        (elem) => elem.id === this.id
      );
      return photos;
    },
  },
});
</script>

<style lang="scss" scoped>
.carousel {
  &__item {
    min-height: 200px;
    width: 100%;
    background-color: $primary;
    color: $white;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__slide {
    padding: 10px;
  }
  &__prev,
  &__next {
    box-sizing: content-box;
    border: 5px solid white;
  }
  &__img {
    max-width: 100%;
    max-height: 500px;
    object-fit: contain;
    border-radius: 8px;
  }
}
</style>
