<template>
  <div class="care">
    <div class="care__card care__card_desktop">
      <img
        class="care__logo"
        :src="MAINLOGO"
        alt="logo"
        width="120"
        @click="$emit('click', 'main')"
      />
      <div class="care__photos">
        <div class="care__images" @click="$emit('openModal', id)">
          <img class="care__image one" :src="ThreeAfter" alt="" />

          <img class="care__image two" :src="OneAfter" alt="" />
          <img class="care__image three" :src="OneBefore" alt="" />
        </div>
      </div>

      <div class="care__info">
        <img class="care__icon" :src="Leaf" width="70" />
        <p class="care__title">Уход за садом</p>

        <p class="care__text">
          {{ text1 }}
        </p>
      </div>
    </div>

    <!-- mobile -->

    <div class="care__card care__card_mobile">
      <div class="care__info">
        <img class="care__icon" :src="Leaf" width="64" />
        <p class="care__title">Уход за садом</p>

        <p class="care__text">
          {{ text1 }}
        </p>

        <div class="care__photos">
          <img
            class="care__logo"
            :src="MAINLOGO"
            alt="logo"
            width="80"
            @click="$emit('click', 'main')"
          />

          <div class="care__images" @click="$emit('openModal', id)">
            <img class="care__image one" :src="ThreeAfter" alt="" />

            <img class="care__image two" :src="OneAfter" alt="" />
            <img class="care__image three" :src="OneBefore" alt="" />
          </div>
        </div>
        <Button class="care__button" />
      </div>
    </div>

    <!-- <ContactPanel class="care__contact-panel" /> -->
  </div>
</template>

<script>
import Leaf from "@/assets/img/careleaf.png";
import MAINLOGO from "@/assets/img/MAINLOGO.png";
import Button from "@/components/Button.vue";

import OneAfter from "@/assets/img/care1.jpg";
import OneBefore from "@/assets/img/care2.jpg";
import ThreeAfter from "@/assets/img/care3.jpg";

import ContactPanel from "@/components/ContactPanel.vue";

export default (await import("vue")).defineComponent({
  name: "Care",

  components: { Button, ContactPanel },

  emits: ["openModal", "click"],

  data: () => ({
    id: "care",

    Leaf,
    MAINLOGO,

    OneAfter,
    OneBefore,
    ThreeAfter,

    text1: `Посаженные растения в саду требуют внимания и заботы.\nДля этого необходимо проводить регулярный уход в саду:\n-совершать обрезку растений (стрижка газонов и живых\nизгородей, санитарная и формовочная обрезка кустов и\nдеревьев)\n-регулярно вносить удобрения\n-удалять сорняки\n-бороться с вредителями\nПредлагаем комплексный уход за садом, а так же составление\nтехнологиечских карт по уходу за садом.`,
    text2: `Этапы работы:\n-закладка всех инженерных коммуникаций (при необходимости)\n-закладка дренажной системы\n-разбивка дорожек, площадок, зонирование участка\n-работа с рельефом участка (выравнивание, создание терасс, подпорных стенок)\n-строительство дорожек, площадок\n-монтаж садового освещения\n-монтаж системы полива.`,
    text3: `Эскизный проект - разрабатывается план сада, его стилистика,\nделается визуализация 3D.`,
    text4: `Рабочая документация - разрабатываются все необходимые\nчертежи для создания сада.`,
    text5: `Перед началом проектирвоания необходим выезд на объект и\nвстреча с заказчиком.`,
  }),
});
</script>

<style lang="scss" scoped>
.care {
  background-image: url("../assets/img/back2.png");
  background-size: cover;

  display: grid;

  &__card {
    grid-column: 1 / -1;
    display: grid;
  }

  &__title {
    margin-top: 16px;
    color: $care-title;
    font: {
      size: 24px;
      weight: 700;
    }
    line-height: 32px;
  }
  &__text {
    text-align: center;
    margin-top: 16px;
    color: $care-text;
    font: {
      size: 16px;
      weight: 500;
    }
    line-height: 24px;
    white-space: pre-wrap;
  }
  &__images {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
  }

  &__image {
    border-radius: 16px;
    box-shadow: 0px 8px 4px 1px rgba($color: #000000, $alpha: 0.25);
    width: 350px;
    height: 250px;
    object-fit: cover;
  }

  .one {
    transform: translateY(80px);
  }
  .two {
    align-self: flex-end;
    transform: translateY(-60px) translateX(30px);
  }
  .three {
    transform: translateY(-120px);
  }
}

@media screen and (min-width: $min-laptop-lg) {
  .care {
    grid-template-columns: $grid-laptop;
    min-height: 100vh;

    position: relative;

    &__logo {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    &__contact-panel {
      display: none;
    }

    &__card {
      grid-template-columns: $grid-laptop;
      &_mobile {
        display: none;
      }
    }

    &__info {
      grid-column: 7 / -1;
      display: flex;
      padding: 96px 0px 32px 0px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 16px;

      &_mobile {
        display: none;
      }
    }
    &__photos {
      grid-column: 1 / 7;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 24px;
      gap: 16px;

      &_mobile {
        display: none;
      }
    }
    &__button {
      margin-top: 24px;
      width: 400px;
    }

    &__icon {
      width: 100px;
    }
    &__title {
      margin-top: 32px;
    }

    &__text {
      font-size: 20px;
      line-height: 20.5px;
    }

    .one,
    .two,
    .three {
      width: 500px;
      object-fit: cover;
    }

    .one,
    .three {
      align-self: center;
    }
  }
}

@media screen and (min-width: $min-laptop) and (max-width: $max-laptop) {
  .care {
    grid-template-columns: $grid-laptop;
    min-height: 100vh;

    position: relative;

    &__logo {
      position: absolute;
      left: 20px;
      top: 20px;
    }

    &__contact-panel {
      display: none;
    }

    &__card {
      grid-template-columns: $grid-laptop;
      &_mobile {
        display: none;
      }
    }

    &__info {
      grid-column: 7 / -1;
      display: flex;
      padding: 32px 0px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &_mobile {
        display: none;
      }
    }
    &__photos {
      grid-column: 1 / 7;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 24px;
      gap: 16px;

      &_mobile {
        display: none;
      }
    }
    &__button {
      margin-top: 24px;
      width: 400px;
    }
  }
}

@media screen and (max-width: $max-tablet) {
  .care {
    grid-template-columns: $grid-mobile;

    background-image: url("../assets/img/back21.png");

    &__contact-panel {
      grid-column: 1 / -1;
    }

    &__card {
      background-color: rgba($color: $white, $alpha: 0.1);
      box-shadow: 0px 8px 10px 1px rgba($color: #000000, $alpha: 0.25);
      border-radius: 8px;
      margin: 8px 16px;

      grid-template-columns: $grid-mobile;
      &_desktop {
        display: none;
      }
    }

    &__logo {
      display: none;
    }

    &__info {
      grid-column: 1 / -1;
      display: flex;
      padding: 32px 8px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      &_desktop {
        display: none;
      }
    }
    &__photos {
      grid-column: 1 / -1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      &_desktop {
        display: none;
      }
    }
    &__button {
      margin-top: 32px;
      grid-column: 1 / -1;
    }
    &__images {
      margin-top: 24px;
      width: 100%;
      justify-content: flex-start;
    }
    &__image {
      width: 120px;
    }

    &__image {
      border-radius: 16px;
      box-shadow: 0px 8px 4px 1px rgba($color: #000000, $alpha: 0.25);
      width: 250px;
      height: 170px;
      object-fit: cover;
    }

    .two {
      align-self: center;
      transform: translateY(-30px);
    }
    .three {
      align-self: flex-end;
      transform: translateY(-60px);
    }
  }
}
</style>
