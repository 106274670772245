<template>
  <div>
    <div class="desktop">
      <div class="contacts">
        <img
          :src="MAINLOGO"
          alt="logo"
          width="120"
          class="logo"
          @click="$emit('click', 'main')"
        />
        <div class="contacts__info">
          <p class="contacts__title">Контакты</p>

          <p class="contacts__text">{{ text }}</p>

          <p class="contacts__text">Russgarden@yandex.ru</p>

          <Button class="contacts__button" />

          <div class="contacts__actions">
            <a href="https://wa.me/+79254682965">
              <img class="icon" :src="WA" alt="WhatsApp" />
            </a>

            <a href="tg://resolve?domain=Russgarden">
              <img class="icon" :src="TG" alt="Telegram" />
            </a>
            <p class="contacts__phone">+7 (925) 468-29-65</p>
          </div>

          <div>
            <a href="tel:+74950030012" class="contacts__phone from_fiks">
              8 (495) 003-00-12
            </a>
            <a href="tel:+79257416862" class="contacts__phone from_fiks">
              8 (925) 741-68-62
            </a>
          </div>
        </div>
        <img
          class="contacts__map"
          src="../assets/img/map.png"
          alt="map"
          @click="openMap"
        />
      </div>
    </div>

    <div class="mobile">
      <div class="contacts">
        <!-- <img
          :src="MAINLOGO"
          alt="logo"
          width="120"
          class="logo"
          @click="$emit('click', 'main')"
        /> -->
        <div class="contacts__info">
          <p class="contacts__title">Контакты</p>

          <p class="contacts__text">{{ text }}</p>

          <p class="contacts__text">Russgarden@yandex.ru</p>

          <img
            class="contacts__map"
            src="../assets/img/map.png"
            alt="map"
            @click="openMap"
          />

          <Button class="contacts__button" />
          <!-- <div class="contacts__actions">
            <a href="https://wa.me/+79254682965">
              <img class="icon" :src="WA" alt="WhatsApp" />
            </a>

            <a href="tg://resolve?domain=Russgarden">
              <img class="icon" :src="TG" alt="Telegram" />
            </a>
            <p class="contacts__phone">+7 (925) 468-29-65</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

import TG from "@/assets/img/tg.png";
import WA from "@/assets/img/wa.png";
import MAINLOGO from "@/assets/img/MAINLOGO.png";

export default (await import("vue")).defineComponent({
  name: "Contacts",

  components: {
    Button,
  },

  data: () => ({
    text: `Адрес офиса: г. Щелково, ул. Фабричная, д. 1, \nБизнес-центр (Мирекс) оф. 425.`,

    WA,
    TG,
    MAINLOGO,
  }),

  methods: {
    openMap() {
      window.open(
        "https://2gis.ru/shchelkovo/firm/4504127908603363/38.012696%2C55.914071?m=38.01588%2C55.914312%2F16%2Fp%2F45%2Fr%2F12.82",
        "_blank"
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.from_fiks {
  text-decoration: none;
  padding-top: 30px;
  text-align: right;
  display: block;
}

.contacts {
  width: 100%;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;

  background-image: url("../assets/img/contact.png");
  background-size: cover;

  .logo {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .icon {
    width: 56px;
    cursor: pointer;
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin-top: 16px;
    color: $button-bg;
    font: {
      size: 24px;
      weight: 700;
    }
    line-height: 32px;
  }

  &__text {
    text-align: center;
    margin-top: 16px;
    color: $button-bg;
    font: {
      size: 20px;
      weight: 600;
    }
    line-height: 20.5px;
    white-space: pre-wrap;
  }

  &__phone {
    font: {
      size: 24px;
      weight: 700;
    }
    line-height: 32px;
    color: $title;
  }

  &__map {
    width: 500px;
    margin-top: 24px;
    box-shadow: 0px 8px 10px 1px rgba($color: #000000, $alpha: 0.25);
    border-radius: 8px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: 48px;
    margin-top: 128px;
  }
}

@media screen and (min-width: $max-laptop) {
  .mobile {
    display: none;
  }
  .contacts {
    height: 100vh;

    position: relative;
    .mobile {
      display: none;
    }
    .logo {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    &__text,
    &__actions,
    &__button {
      margin-top: 40px;
    }

    &__actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &__map {
      width: 500px;
      margin-top: 24px;
      box-shadow: 0px 8px 10px 1px rgba($color: #000000, $alpha: 0.25);
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: 48px;
      margin-top: 128px;
    }
  }
}

@media screen and (min-width: $min-laptop) and (max-width: $max-laptop) {
  .mobile {
    display: none;
  }
  .contacts {
    height: 100vh;

    position: relative;

    .logo {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    &__text,
    &__actions,
    &__button {
      margin-top: 40px;
    }

    &__actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &__map {
      width: 400px;
      box-shadow: 0px 8px 10px 1px rgba($color: #000000, $alpha: 0.25);
      border-radius: 24px;
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: 48px;
      margin-top: 128px;
    }
  }
}

@media screen and (max-width: $max-tablet) {
  .contacts {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100vh;

    &__info {
      padding: 16px;
      border-radius: 8px;
      width: 100%;
      gap: 24px;
    }

    &__map {
      width: 300px;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }

    &__actions {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 40px;
    }

    &__phone {
      width: fit-content;
    }
  }

  .desktop {
    display: none;
  }
}
</style>
