import { defineStore } from "pinia";
import { API } from "../api/form";

export const useFormStore = defineStore("form", {
  state: () => ({
    form: {
      name: "",
      phone: "",
    },
    isOpen: false,
    isLoading: false,
  }),

  getters: {
    isDisabledButton: (state) => {
      return !state.form.name || !state.form.phone || state.isLoading;
    },
  },

  actions: {
    resetForm() {
      this.form = {
        name: "",
        phone: "",
      };
    },

    openForm() {
      this.isOpen = true;
    },
    closeForm() {
      this.isOpen = false;
      this.resetForm();
    },

    async sendForm() {
      try {
        this.isLoading = true;

        const body = {
          ...this.form,
        };
        await API.post("form", body);

        this.resetForm();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
