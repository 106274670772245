<template>
  <div class="main">
    <img src="../assets/img/logowhite.png" class="main__logo" />

    <div class="main__content">
      <div class="main__header">
        <!-- <p class="main__tab" @click="navigateTo('about')">О Компании</p> -->
        <p class="main__tab" @click="navigateTo('portfolio')">Портфолио</p>
        <p class="main__tab" @click="navigateTo('projection')">
          Проектирование
        </p>
        <p class="main__tab" @click="navigateTo('blago')">Благоустройство</p>
        <p class="main__tab" @click="navigateTo('green')">Озеленение</p>
        <p class="main__tab" @click="navigateTo('care')">Уход</p>
        <p class="main__tab" @click="navigateTo('contacts')">Контакты</p>
      </div>

      <div class="main__text">
        <div class="main__with-logo">
          <div class="main__message">
            <p class="main__title">Делаем сады под «ключ»</p>
            <p class="main__description">
              Сад - прекрасное место для отдыха человека.<br />
              Наши архитекторы, инженеры, дендрологи<br />
              продумывают каждую деталь в проекте и<br />
              вместе с опытными строителями и садовниками<br />
              воплощают их в жизнь
            </p>
          </div>

          <Icon class="main__leaf" name="leaf" />
        </div>

        <Button class="main__button" />
      </div>
    </div>

    <ContactPanel class="main__panel" />
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import ContactPanel from "@/components/ContactPanel.vue";
import Icon from "@/components/Icon.vue";

export default (await import("vue")).defineComponent({
  name: "Main",

  components: { Button, Icon, ContactPanel },

  emits: ["click"],

  methods: {
    navigateTo(id) {
      this.$emit("click", id);
    },
  },
});
</script>

<style lang="scss" scoped>
.main {
  &__title {
    text-align: center;
  }
}

@media screen and (min-width: $min-laptop-lg) {
  .main {
    min-height: 100vh;
    background-image: url("../assets/img/main.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: min-content;
    position: relative;

    &__panel {
      grid-column: 1 / -1;
      margin-top: auto;
    }

    &__leaf {
      width: 150px;
    }

    &__with-logo {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }

    &__logo {
      width: 350px;
      grid-column: 1 / 3;
      transform: translateX(80px);
      margin-top: 32px;
    }
    &__content {
      grid-column: 3 / -1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-top: 32px;
    }
    &__header {
      padding: 24px;
      background-image: url("../assets/img/header.png");
      background-size: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-bottom: 32px;
      grid-column: 4 / -1;
    }
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      padding: 40px 24px;
      position: relative;
      margin-top: 20px;
      z-index: 1;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 90%;
        content: "";
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 190px 0px 0px 190px;
        z-index: -1;
      }
    }
    &__tab {
      cursor: pointer;
      padding: 0;
      margin: 0;
      font: {
        size: 20px;
        weight: 700;
      }
      line-height: 20.5px;
      color: $white;
    }
    &__title {
      color: $white;
      font: {
        size: 32px;
        weight: 600;
      }
      line-height: 40px;
      text-transform: uppercase;
    }
    &__description {
      color: $white;
      font: {
        size: 24px;
        weight: 600;
      }
      line-height: 32px;
      text-align: center;
    }
    &__button {
      width: 300px;
    }
  }
}

@media screen and (min-width: 1141px) and (max-width: $max-laptop) {
  .main {
    min-height: 100vh;
    background-image: url("../assets/img/main.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: min-content;
    position: relative;

    &__leaf {
      width: 130px;
    }

    &__panel {
      grid-column: 1 / -1;
      margin-top: auto;
    }

    &__with-logo {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }

    &__logo {
      width: 200px;
      grid-column: 1 / 1;
      transform: translateX(40px);
      margin-top: 32px;
    }
    &__content {
      grid-column: 2 / -1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-top: 32px;
    }
    &__header {
      padding: 24px;
      background-image: url("../assets/img/header.png");
      background-size: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-bottom: 32px;
      grid-column: 2 / -1;
    }
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      padding: 40px 24px;
      position: relative;
      margin-top: 20px;
      z-index: 1;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        content: "";
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 190px 0px 0px 190px;
        z-index: -1;
      }
    }
    &__tab {
      cursor: pointer;
      padding: 0;
      margin: 0;
      font: {
        size: 16px;
        weight: 700;
      }
      line-height: 16.5px;
      color: $white;
    }
    &__title {
      color: $white;
      font: {
        size: 28px;
        weight: 600;
      }
      line-height: 40px;
      text-transform: uppercase;
    }
    &__description {
      color: $white;
      font: {
        size: 20px;
        weight: 600;
      }
      line-height: 32px;
      text-align: center;
    }
    &__button {
      width: 300px;
    }
  }
}

@media screen and (min-width: $min-laptop) and (max-width: 1140px) {
  .main {
    min-height: 100vh;
    background-image: url("../assets/img/main.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: min-content;
    position: relative;

    &__leaf {
      width: 130px;
    }

    &__panel {
      grid-column: 1 / -1;
      margin-top: auto;
    }

    &__with-logo {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }

    &__logo {
      width: 200px;
      grid-column: 1 / 1;
      transform: translateX(40px);
      margin-top: 32px;
    }
    &__content {
      grid-column: 2 / -1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-top: 32px;
    }
    &__header {
      padding: 16px;
      background-image: url("../assets/img/header.png");
      background-size: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-bottom: 32px;
      grid-column: 2 / -1;
    }
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      padding: 40px 24px;
      position: relative;
      margin-top: 20px;
      z-index: 1;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        content: "";
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 190px 0px 0px 190px;
        z-index: -1;
      }
    }
    &__tab {
      cursor: pointer;
      padding: 0;
      margin: 0;
      font: {
        size: 16px;
        weight: 700;
      }
      line-height: 20px;
      color: $white;
    }
    &__title {
      color: $white;
      font: {
        size: 28px;
        weight: 600;
      }
      line-height: 40px;
      text-transform: uppercase;
    }
    &__description {
      color: $white;
      font: {
        size: 20px;
        weight: 600;
      }
      line-height: 32px;
      text-align: center;
    }
    &__button {
      width: 300px;
    }
  }
}

@media screen and (min-width: $min-tablet) and (max-width: $max-tablet) {
  .main {
    min-height: 100vh;
    background-image: url("../assets/img/main.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: relative;

    &__leaf {
      display: none;
    }

    &__panel {
      width: 100%;
      margin-top: auto;
    }

    &__with-logo {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 16px;
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }

    &__logo {
      width: 250px;
      margin-top: 24px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      z-index: 10;
      position: relative;
      margin-top: 24px;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-image: url("../assets/img/bgmobile.png");
        background-size: 100%;
        transform: translateY(-100px);
      }
    }
    &__header {
      display: none;
    }
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      padding: 40px 24px;
      position: relative;
      margin-top: 20px;
      z-index: 1;
    }

    &__title {
      color: $white;
      font: {
        size: 24px;
        weight: 600;
      }
      line-height: 28px;
      text-transform: uppercase;
    }
    &__description {
      color: $white;
      font: {
        size: 16px;
        weight: 600;
      }
      line-height: 24px;
      text-align: center;
    }
    &__button {
      width: 300px;
    }
  }
}

@media screen and (min-width: $min-mobile) and (max-width: $max-mobile) {
  .main {
    min-height: 100vh;
    background-image: url("../assets/img/main.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1000;

    &__leaf {
      display: none;
    }

    &__panel {
      width: 100%;
      margin-top: auto;
      position: fixed;
      bottom: 0;
    }

    &__with-logo {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 16px;
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }

    &__logo {
      width: 250px;
      margin-top: 24px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      z-index: 10;
      position: relative;
      margin-top: 24px;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-image: url("../assets/img/bgmobile.png");
        background-size: 100%;
        transform: translateY(-90px);
      }
    }
    &__header {
      display: none;
    }
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      padding: 40px 24px;
      position: relative;
      margin-top: 20px;
      z-index: 1;
    }

    &__title {
      color: $white;
      font: {
        size: 24px;
        weight: 600;
      }
      line-height: 28px;
      text-transform: uppercase;
    }
    &__description {
      color: $white;
      font: {
        size: 16px;
        weight: 600;
      }
      line-height: 24px;
      text-align: center;
    }
    &__button {
      width: 300px;
    }
  }
}
</style>
