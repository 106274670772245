<script setup>
import { useFormStore } from "../store/form.store";

import { computed } from "vue";

const formStore = useFormStore();

const isOpen = computed(() => formStore.isOpen);
</script>

<template>
  <Teleport to="body">
    <Transition name="modal">
      <div v-if="isOpen" @mouseup.self="formStore.closeForm" class="modal">
        <div class="modal__content">
          <div class="modal__body">
            <div class="modal__form">
              <div class="modal__title">Оставьте заявку на консультацию</div>

              <div class="modal__inputs">
                <input
                  class="modal__input"
                  placeholder="Имя"
                  v-model="formStore.form.name"
                />
                <input
                  class="modal__input"
                  placeholder="Телефон"
                  v-model="formStore.form.phone"
                />
              </div>

              <button
                class="modal__button"
                :class="{
                  modal__button_disabled: formStore.isDisabledButton,
                }"
                :disabled="formStore.isDisabledButton"
                @click="formStore.sendForm"
              >
                Получить консультацию
              </button>
              <p class="modal__subtitle">Или позвоните по телефону</p>
              <p class="modal__phone">+7 (925) 468-29-65</p>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  padding: 0px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  background-color: rgba($color: #000000, $alpha: 0.7);

  &__content {
    max-width: 50%;
    border-radius: 16px;
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    box-shadow: 0 4px 15px rgba(135, 144, 167, 0.2);
    border: 2px solid $form;
  }

  &__body {
    flex: 1;
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-family: "SST";
  }

  &__title {
    font-size: 24px;
    line-height: 24.5px;
    font-weight: 700;
    color: $form;
  }

  &__inputs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }

  &__input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #bababa;
    width: 386px;
    font-size: 16px;
    line-height: 16.5px;
    font-family: "SST";
  }

  &__button {
    background-color: $form;
    outline: none;
    border: none;
    padding: 8px 16px;
    color: $white;
    font-family: "SST";
    font-size: 20px;
    line-height: 20.5px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    width: 386px;
    margin-top: 4px;

    &_disabled {
      cursor: not-allowed;
    }
  }

  &__subtitle {
    color: $form2;
    font-size: 16px;
    line-height: 16.5px;
    font-weight: 600;
  }

  &__phone {
    font-size: 32px;
    line-height: 32.5px;
    font-weight: 700;
    color: $form2;
  }
}

.modal-enter-from,
.modal-leave-to {
  transform: translateY(100%);
}

.modal-enter-from,
.modal-leave-to > .modal__content {
  transform: translateY(-100%);
}

.modal-enter-active,
.modal-leave-active {
  transition: transform 0.3s;
}

.modal-enter-active,
.modal-leave-active > .modal__content {
  transition: transform 0.3s;
}
</style>
