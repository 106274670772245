<template>
  <div class="contact-panel">
    <div class="contact-panel__icons">
      <a href="https://wa.me/+79254682965">
        <img class="icon" :src="WA" alt="WhatsApp" />
      </a>

      <a href="tg://resolve?domain=Russgarden">
        <img class="icon" :src="TG" alt="Telegram" />
      </a>
    </div>

    <p class="contact-panel__phones">
      <a href="tel:+79254682965" class="contact-panel__phone">
        +7 (925) 468-29-65
      </a>
      <a href="tel:+74950030012" class="contact-panel__phone">
        8 (495) 003-00-12
      </a>
      <a href="tel:+79257416862" class="contact-panel__phone">
        8 (925) 741-68-62
      </a>
    </p>
  </div>
</template>

<script>
import TG from "@/assets/img/tg.png";
import WA from "@/assets/img/wa.png";

export default (await import("vue")).defineComponent({
  name: "ContactPanel",

  data: () => ({
    WA,
    TG,
  }),
});
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  padding: 0;
}
.contact-panel {
  width: 100%;
  height: fit-content;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: rgba($color: $white, $alpha: 0.6);

  padding: 4px 8px;

  .icon {
    width: 56px;
    cursor: pointer;
  }
  &__icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__phones {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__phone {
    font: {
      size: 24px;
      weight: 700;
    }
    line-height: 32px;
    color: $title;
    text-decoration: none;
  }
}
</style>
