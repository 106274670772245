import axios from "axios";

export const API = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

API.interceptors.response.use(
  (resolve) => resolve.data,
  (error) => Promise.reject(error)
);
